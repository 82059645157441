.not-found {
  text-align: center;
  padding: 2rem 0;
}
img.noroute-bg {
  position: absolute;
  left: 0;
  top: 4rem;
  right: 0;
  margin: 0 auto;
  width: 250px;
  z-index: -1;
}
img.noroute-ballons {
  display: none;
  z-index: -1;
}
img.noroute-triangle {
  display: none;
  z-index: -1;
}
.heading {
  font-size: 6rem;
  font-weight: bold;
  color: #939393;
  margin-bottom: 0;
}
.subheading {
  font-size: 1.5rem;
  font-weight: bolder;
  color: #939393;
}
.paragraph {
  font-size: 1rem;
  color: #939393;
  max-width: 25rem;
  margin: 0 auto 2rem;
}

@media (min-width: 768px) {
  .not-found {
    padding: 4rem 0;
  }
  img.noroute-bg {
    width: 300px;
  }
  img.noroute-ballons {
    display: block;
    position: absolute;
    left: 5rem;
    width: 200px;
    top: 15rem;
  }
  img.noroute-triangle {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
  }
}

@media (min-width: 992px) {
  .not-found {
    padding: 10rem 0;
  }
  img.noroute-bg {
    top: 8rem;
    width: 400px;
  }
  img.noroute-ballons {
    left: 10rem;
    width: 300px;
  }
  img.noroute-triangle {
    width: 350px;
  }
}
