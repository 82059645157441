.table-action-button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-justify {
  justify-content: center;
}

@media (min-width: 768px) {
  .pagination-justify {
    justify-content: flex-end;
  }
}
