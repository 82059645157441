body {
  font-family: "Roboto", sans-serif;
}

/* override input styles */
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: #495057;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("./icons/calendar.svg") no-repeat center;
  height: 100%;
}
input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.form-control::placeholder {
  color: #9e9e9e;
}
.custom-file-label {
  display: flex;
  justify-content: space-between;
  color: #9e9e9e;
}
.custom-file-label p {
  color: #495057;
}
.custom-file-label::after {
  display: none;
}
label span {
  color: red;
}

/* override button styles */
.btn-default {
  border-radius: 30px;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
  min-width: 120px;
  margin: 0;
}

/* override table styles */
th {
  font-size: 16px !important;
  border: none !important;
}
tbody {
  border: 1px solid #d8d8d8;
  background-color: white;
}
th,
td {
  font-weight: 700 !important;
}
td {
  border: none !important;
  vertical-align: middle !important;
}

/* override pagination styles */
.pagination {
  flex-wrap: wrap;
  justify-content: center;
}
.pagination .page-item.active .page-link {
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 1) 0%,
    rgba(67, 186, 148, 1) 120%
  );
}

/* override navbar styles */
.navbar-brand img {
  height: 30px;
}
.nav-link {
  color: #006881 !important;
}

@media (min-width: 768px) {
  .nav-link {
    color: white !important;
  }
}
