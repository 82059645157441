.status-justify {
  justify-content: center;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .status-justify {
    justify-content: flex-end;
    margin-top: 0;
  }
}
