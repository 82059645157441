body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */
.create-color-primary {
  color: #006881;
}
.create-bg-white {
  background-color: #ffffff;
}
.create-bg-primary {
  background-color: #006881;
}
.create-gradient-primary {
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 1) 0%,
    rgba(67, 186, 148, 1) 120%
  );
}
.create-divider-primary {
  border: 1px solid rgba(0, 104, 129, 0.5);
  margin: 0;
}
.create-divider-big {
  width: 100px;
  margin: 0 auto;
}
.create-divider-small {
  width: 75px;
}
.create-text-small {
  font-size: 12px;
  color: grey;
  margin-bottom: 0.5rem;
}
.create-bg-ellipse {
  position: absolute;
  left: -0.5rem;
  top: -0.5rem;
  border-radius: 0 50% 50% 0;
  height: 100px;
  width: 100px;
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 0.5) 0%,
    rgba(67, 186, 148, 0.1) 120%
  );
  z-index: 0;
}
.create-error-message {
  color: red;
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 14px;
}
.create-row-gap {
  row-gap: 1rem;
  grid-row-gap: 1rem;
}
.create-row-gap-big {
  row-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}
.create-jumbotron {
  padding: 5rem 0;
}
.create-main-content {
  padding: 0 0 5rem;
}
.create-button-container {
  padding-top: 7rem;
}
.insurance-name-text {
  margin-top: 1rem;
}
.policy-date-text {
  margin-top: 2rem;
}
img.woman {
  position: absolute;
  height: 12rem;
  right: 0;
  top: 0;
  z-index: -1;
}
img.bg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 700px;
  z-index: -1;
}
img.boy {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
img.ellipse {
  position: absolute;
  right: 0;
  top: 0;
  width: 22rem;
}
img.healthcare {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 5rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  img.woman {
    height: 25rem;
  }
  .insurance-name-text {
    margin-top: 0;
  }
  .policy-date-text {
    margin-top: 0;
    width: 75%;
  }
  .policy-date-wording-text {
    width: 75%;
  }
  img.healthcare {
    width: 8rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .create-jumbotron {
    padding: 10rem 0;
  }
  .create-main-content {
    padding: 3rem 0 15rem;
  }
  img.woman {
    height: 32rem;
  }
  img.boy {
    right: 40px;
    top: 20px;
  }
  img.healthcare {
    right: 2rem;
    top: 2rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
